import http from "@/services/http";

const users = (params) => { return http.get("users", { params: params }) }
const getById = (id) => http.get(`users/${id}`)
const store = (user) => http.post('users', user)
const update = (id, user) => http.put(`users/${id}`, user)
const destroy = (userId) => http.delete(`user/${userId}`)
const invite = (emails) => http.post(`user/invite-users`, {'emails': emails})
const getInviteById = (id) => http.get(`invite-users/${id}`)
const registerInvited = (inviteId, user) => http.post(`auth/invite-users/${inviteId}`, user)


export default {
  users,
  getById,
  store,
  update,
  destroy,
  invite,
  getInviteById,
  registerInvited
}
