import axios from 'axios';
import store from '@/store';
import jwt_decode from "jwt-decode";
import router from '@/router';

const baseUrl = process.env.VUE_APP_API_URL;
let http = axios.create({
  baseURL: baseUrl + '/api/v1/',
});

function basicRequest(config) {
  let newConfig = config;
  let newHeaders = newConfig.headers || {};
  let tokenJson = localStorage.getItem('token');
  if (tokenJson) {
    let token = JSON.parse(tokenJson);
    newHeaders.Authorization = token.access_token;
  }
  newConfig.headers = newHeaders;
  return newConfig;
}

http.interceptors.request.use(basicRequest);
http.interceptors.response.use(null, handleUnauthorized);

async function handleUnauthorized(error) {
  if (error.response.status === 401 && error.response) {
    const response = await http
      .post("auth/refresh")
      .then(response => {
        if (response.data.error) {
          setLogout()
        }
        me()
        setToken(response.data.access_token, response.data.expires_in, response.data.email_verified);
        location.reload();
        return true;
      }).catch(() => {
        setLogout()
      });
    return response;
  }
  return Promise.reject(error);
}

const me = () => {
  return http
    .post("auth/me")
    .then(response => {
      store.commit('SET_USER', response.data)
      return response;
    });
}

const setLogout = () => {
  localStorage.removeItem("token");
  store.commit("SET_PREVENT_REDIRECTION");
  store.commit("SET_LOGOUT", true);
  router.push({ name: 'login' });
}

const setToken = (accessToken, expiresIn, emailVerified) => {
  let now = new Date();
  now.setSeconds(now.getSeconds() + expiresIn);
  let token = {
    expires_at: now.toLocaleString("en-US", { timeZone: "America/Sao_Paulo" }),
    access_token: `Bearer ${accessToken}`,
    email_verified: emailVerified
  };
  localStorage.setItem("token", JSON.stringify(token));
  store.commit("SET_PERMISSIONS", jwt_decode(token.access_token).permissions);
}

export default http;
