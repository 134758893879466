import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import authRoutes from './routes/auth';
import quotationRoutes from './routes/quotation';
import panelRoutes from './routes/panel';
import lawyerRoutes from './routes/lawyer';
import authenticationService from '@/services/authentication';

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    redirect: "login"
  },
  {
    path: '/',
    name: 'home',
    redirect: 'quotation',
    component: Home
  },
  ...authRoutes,
  ...quotationRoutes,
  ...panelRoutes,
  ...lawyerRoutes
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// eslint-disable-next-line
router.beforeEach(async (to, from, next) => {
  const listRoutesAllowed = [
    'login', 'register', 'forgot-password', 'reset-password', 'login-landing', 'register-success', 'register-invited',
    'lawyer', 'step-1', 'step-2', 'step-3', 'step-4', 'step-5', 'step-6', 'step-7', 'step-8'
  ];

  if (! listRoutesAllowed.includes(to.name)) {
    //VERIFICANDO SE O TOKEN É VALIDO
    await authenticationService.hasTokenValid(next)
    //VERIFICANDO SE ELE TEM ACESSO DE ADMINISTRADOR
    if (to.meta.allowOnlyMaster) {
      authenticationService.tryAccessAsMaster(to, next);
      return;
    }
  }
  next()
})

export default router
