<template>
  <div class="container container-cozy">
    <div class="card card-page">
      <div class="card-header border-bottom">
        <h2 class="text-center uppercase">Solicite seu acesso</h2>
      </div>
      <div class="card-body shadow">
        <validation-observer ref="observer" v-slot="{ handleSubmit, invalid }">
          <b-form
            @submit.stop.prevent="handleSubmit(register)"
            class="form"
            autocomplete="off"
          >
            <div class="row">
              <!--<div class="col-12 col-md-8 offset-md-2 col mb-3 text-center">
                <label class="dbo-ui">Selecione o tipo de cadastro</label>
                 <div class="list-group list-group-horizontal">
                  <a
                    @click="changeToLegalType()"
                    class="list-group-item list-group-item-action"
                    :class="{ active: legalSelected }"
                  >
                    Pessoa jurídica
                  </a>
                  <a
                    @click="changeToNaturalType()"
                    class="list-group-item list-group-item-action disabled"
                    >Pessoa física</a
                  >
                  <a
                  @click="changeToNaturalType()"
                  class="list-group-item list-group-item-action"
                  :class="{ 'active': naturalSelected }"
                  >Pessoa física</a
                > 
                </div>
              </div> -->

              <div class="col-12 text-center">
                <hr />
                <div class="hr-label">Preencha seus dados abaixo</div>
              </div>

              <div class="col-12" v-if="alertMessage">
                <div
                  class="alert-custom alert-danger-custom list-errors-custom"
                >
                  <ul
                    class="pt-3 list-errors"
                    v-if="Array.isArray(alertMessage)"
                  >
                    <li v-for="er in alertMessage" :key="er">{{ er[0] }}</li>
                  </ul>
                  <div v-else>
                    {{ alertMessage }}
                  </div>
                </div>
              </div>

              <div class="col-12 col-lg-6 mb-3">
                <validation-provider
                  name="Nome"
                  rules="required|max:191|fullname"
                  v-slot="validationContext"
                >
                  <label class="dbo-ui">Seu nome completo</label>
                  <b-form-input
                    v-model="user.name"
                    :state="getValidationState(validationContext)"
                    placeholder="Preencha seu nome completo"
                  ></b-form-input>
                  <b-form-invalid-feedback>{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </validation-provider>
              </div>

              <div class="col-12 col-lg-6 mb-3">
                <validation-provider
                  name="Documento"
                  rules="required|min:14"
                  v-slot="validationContext"
                  vid="document"
                >
                  <label class="dbo-ui">{{ documentLabel }}</label>
                  <b-form-input
                    v-model="user.document"
                    :state="getValidationState(validationContext)"
                    :placeholder="documentPlaceholder"
                    v-mask="documentMask"
                  ></b-form-input>
                  <b-form-invalid-feedback>{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </validation-provider>
              </div>

              <div class="col-12 col-lg-6 mb-3">
                <validation-provider
                  name="Telefone"
                  rules="required"
                  v-slot="validationContext"
                  vid="phone"
                >
                  <label class="dbo-ui">Telefone de contato</label>
                  <b-form-input
                    v-model="user.phone"
                    v-mask="['(##) ####-####', '(##) #####-####']"
                    :state="getValidationState(validationContext)"
                    placeholder="Preencha seu telefone principal"
                  ></b-form-input>
                  <b-form-invalid-feedback>{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </validation-provider>
              </div>

              <div class="col-12 col-lg-6 mb-3">
                <validation-provider
                  name="E-mail"
                  rules="required|email"
                  v-slot="validationContext"
                  vid="email"
                >
                  <label class="dbo-ui">E-mail</label>
                  <b-form-input
                    type="email"
                    v-model="user.email"
                    :state="getValidationState(validationContext)"
                    placeholder="Preencha seu e-mail principal"
                  ></b-form-input>
                  <b-form-invalid-feedback>{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </validation-provider>
              </div>

              <div class="col-12 col-lg-6 mb-3">
                <validation-provider
                  name="Senha"
                  rules="required|min:6"
                  v-slot="validationContext"
                  vid="password"
                >
                  <label class="dbo-ui">Sua senha</label>
                  <b-form-input
                    type="password"
                    v-model="user.password"
                    :state="getValidationState(validationContext)"
                    placeholder="Digite aqui sua senha desejada"
                  ></b-form-input>

                  <b-form-invalid-feedback>{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </validation-provider>
              </div>

              <div class="col-12 col-lg-6 mb-3">
                <validation-provider
                  name="Confirmação de senha"
                  rules="required|confirmed:password"
                  v-slot="validationContext"
                  vid="confirm_password"
                >
                  <label class="dbo-ui">Preencha sua senha novamente</label>
                  <b-form-input
                    type="password"
                    v-model="user.confirm_password"
                    :state="getValidationState(validationContext)"
                    placeholder="Confira sua senha"
                  ></b-form-input>

                  <b-form-invalid-feedback>{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </validation-provider>
              </div>

              <div class="col-12 col-lg-6 mb-3">
                <validation-provider
                  name="Politica de privacidade"
                  rules="required"
                  v-slot="validationContext"
                  vid="policy"
                >
                  <b-form-checkbox
                    v-model="user.policy"
                    :state="user.policy"
                    :placeholder="policyPlaceholder"
                  >
                    <p>Eu li e aceito os 
                    <a class="text-decoration-none" href="https://finnseguros.com.br/politica-de-privacidade/" target="_blank">
                     termos e condições.</a></p>
                  </b-form-checkbox>
                  <b-form-invalid-feedback>{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </validation-provider>
              </div>

              <div class="col-12 mt-3">
                <button
                  type="submit"
                  :disabled="invalid"
                  class="btn btn-primary btn-pretty d-block mx-auto"
                >
                  <b-spinner v-if="loading" small></b-spinner>
                  <i v-else class="fa fa-check"></i> Enviar
                </button>
              </div>
              <div class="col-12 py-3 text-center">
                <hr />
                <span class="hr-label mx-auto">OU</span>
                <p class="text-muted mb-0">
                  Já possui uma conta?
                  <router-link :to="{ name: 'login' }"
                    >Faça seu login!</router-link
                  >
                </p>
              </div>
            </div>
          </b-form>
        </validation-observer>
      </div>
    </div>
  </div>
</template>

<script>
import userTypes from "@/enums/userTypes";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import http from "@/services/http";
import { mask } from "vue-the-mask";
import { extend } from "vee-validate";

extend("fullname", (value) => {
  const nameParts = value.split(" ");
  return nameParts.length > 1 || "Você deve informar seu nome completo";
});

export default {
  name: "Register",
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    mask,
  },
  data: () => ({
    type: userTypes.LEGAL,
    loading: false,
    error: null,
    user: {},
  }),
  computed: {
    naturalSelected() {
      return this.type == userTypes.NATURAL;
    },
    legalSelected() {
      return this.type == userTypes.LEGAL;
    },
    documentLabel() {
      return this.type == userTypes.NATURAL ? "Seu CPF" : "CNPJ da empresa";
    },
    documentPlaceholder() {
      return this.type == userTypes.NATURAL
        ? "Preencha o número do seu CPF"
        : "Preencha com o CNPJ da sua empresa";
    },
    policyPlaceholder() {
      return "Campo politica de privacidade é obrigatorio";
    },
    documentMask() {
      return "##.###.###/####-##";
    },
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    changeToNaturalType() {
      this.type = userTypes.NATURAL;
    },
    changeToLegalType() {
      this.type = userTypes.LEGAL;
    },
    register() {
      this.loading = true;
      this.resetAlert();
      http
        .post("auth/signup", this.user)
        .then((response) => {
          if (response.data.error) {
            this.error = response.data.error;
          }
          this.$gtm.trackEvent({
            event: "Registro_ok",
          });
          this.$router.push({ name: "register-success" });
        })
        .catch((error) => {
          this.showError(Object.values(error.response.data.errors));
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
a {
  cursor: pointer;
}
</style>
