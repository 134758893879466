<template>
  <div class="container container-small">
    <div class="card card-page">
      <div class="card-header border-bottom">
        <h2 class="text-center">Login</h2>
      </div>
      <div class="card-body shadow">
        <validation-observer ref="form" v-slot="{ handleSubmit }">
          <b-form
            @submit.stop.prevent="handleSubmit(login)"
            class="form"
            autocomplete="off"
          >
            <div class="row">
              <div v-if="error" class="col-12">
                <div class="alert-custom alert-danger-custom">{{ error }}</div>
              </div>
              <div class="col-12 mb-3">
                <validation-provider
                  name="E-mail"
                  rules="required|email"
                  v-slot="validationContext"
                >
                  <label class="dbo-ui" for="">Seu e-mail</label>
                  <b-form-input
                    v-model="email"
                    :state="getValidationState(validationContext)"
                  ></b-form-input>

                  <b-form-invalid-feedback>{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </validation-provider>
              </div>
              <div class="col-12 mb-3">
                <validation-provider
                  name="Senha"
                  rules="required"
                  v-slot="validationContext"
                >
                  <label class="dbo-ui" for="">Sua senha</label>
                  <b-form-input
                    type="password"
                    v-model="password"
                    :state="getValidationState(validationContext)"
                  ></b-form-input>

                  <b-form-invalid-feedback>{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </validation-provider>
              </div>
              <div class="col-12 mb-3">
                <div class="d-flex justify-content-end align-items-end">
                  <!-- <b-form-checkbox
                    id="checkbox-"
                    size="sm"
                    class="text-uppercase mb-3"
                    v-model="keepLogged"
                  >
                    Mantenha-me logado
                  </b-form-checkbox> -->
                  <router-link
                    :to="{ name: 'forgot-password' }"
                    class="d-inline-block mb-3 font-12 text-uppercase text-secondary"
                    >Esqueceu sua senha?</router-link
                  >
                </div>
              </div>
              <div class="col-12">
                <b-button
                  type="submit"
                  variant="primary"
                  class="btn-pretty d-block mx-auto"
                >
                  <b-spinner v-if="loading" small></b-spinner>
                  <i v-else class="fa fa-check"></i> Entrar
                </b-button>
              </div>
              <div class="col-12 py-3 text-center">
                <hr />
                <span class="hr-label mx-auto">OU</span>
                <p class="mb-0">
                  Ainda não possui um login?
                  <router-link :to="{ name: 'register' }"
                    >Solicite seu acesso agora!</router-link
                  >
                </p>
              </div>
            </div>
          </b-form>
        </validation-observer>
      </div>
    </div>
  </div>
</template>

<script>
import authenticationService from "@/services/authentication";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapGetters } from "vuex";

export default {
  name: "Login",
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data: () => ({
    email: null,
    password: null,
    keepLogged: false,
    error: null,
    loading: false,
    quotationId: null,
    showTerms: null,
    policyId: null,
  }),
  mounted() {
    this.$refs.form.reset();
  },
  created() {
    this.quotationId = this.$route.query.quotationId;
    this.showTerms = this.$route.query.showTerms;
    this.policyId = this.$route.query.policyId;
    if (
      !this.getPreventRedirection &&
      authenticationService.hasEmailVerified()
    ) {
      if (this.quotationId && this.$can("policy-issue") && !this.policyId) {
        this.$router.push({
          name: "final-feedback-quotation",
          params: { quotationId: this.quotationId },
          query: { showTerms: this.showTerms },
        });
        this.$store.commit("SET_PREVENT_REDIRECTION", false);
        return;
      }
      if (this.policyId) {
        this.$router.push({
          name: "details-policy",
          params: { policyId: this.policyId },
        });
        return;
      }
      this.$router.push({ name: "quotation" });
    }
    this.$store.commit("SET_PREVENT_REDIRECTION", false);
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    async login() {
      this.loading = true;
      this.error = null;
      await authenticationService
        .login(this.email, this.password)
        .then((response) => {
          if (response.data.error) {
            this.error = response.data.error;
            return;
          }

          if (!response.data.email_verified) {
            this.$router.push({
              name: "email-confirmation-pending",
              params: { email: this.email },
            });
            localStorage.removeItem("token");
            this.$store.commit("SET_LOGOUT", false);
            return;
          }
          this.me();
        })
        .catch(() => {
          this.error = "Ocorreu um erro ao tentar realizar o login";
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async me() {
      await authenticationService
        .me()
        .then((response) => {
          if (response.data.error) {
            this.error = response.data.error;
          }
          this.$gtm.trackEvent({
            event: "Login_ok",
          });
          if (this.quotationId && this.$can("policy-issue") && !this.policyId) {
            this.$router.push({
              name: "final-feedback-quotation",
              params: { quotationId: this.quotationId },
              query: { showTerms: this.showTerms },
            });
            return;
          }
          if (this.policyId) {
            this.$router.push({
              name: "details-policy",
              params: { policyId: this.policyId },
            });
            return;
          }
          this.$router.push({ name: "quotation" });
        })
        .catch(() => {
          this.error =
            "Ocorreu um erro ao tentar recuperar informações do usuário";
        });
    },
  },
  computed: {
    ...mapGetters(["getPreventRedirection"]),
  },
};
</script>

<style>
</style>
