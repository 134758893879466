const routes = [
  {
    path: "/cotacao",
    component: () =>
      import(/* webpackChunkName: "quotation" */ "@/views/Quotation/Index"),
    children: [
      {
        path: "",
        name: "quotation",
        component: () =>
          import(/* webpackChunkName: "quotation" */ "@/views/Quotation/Home"),
        meta: {
          anyCan: ['quotation-store', 'quotation-update', 'quotation-edit'],
          onDeniedRoute: { name: 'dashboard', replace: true }
        }
      },
      {
        path: "seguro-garantia",
        component: () =>
          import(
            /* webpackChunkName: "quotation" */ "@/views/Quotation/Guarantee/Index"
          ),
        props: true,
        meta: {
          anyCan: ['quotation-store', 'quotation-update', 'quotation-edit'],
          onDeniedRoute: { name: 'dashboard', replace: true }
        },
        children: [
          {
            path: ":quotationId?",
            name: "garantee-types-selection",
            component: () =>
              import(
                /* webpackChunkName: "quotation" */ "@/views/Quotation/Guarantee/Steps/GaranteeTypesSelection"
              ),
            props: true,
            meta: {
              anyCan: ['quotation-store', 'quotation-update', 'quotation-edit'],
              onDeniedRoute: { name: 'dashboard', replace: true }
            },
          },
          {
            path: "dados-tomador/:quotationId",
            name: "guarantee-quotation",
            component: () =>
              import(
                /* webpackChunkName: "quotation" */ "@/views/Quotation/Guarantee/Steps/GeneralData"
              ),
            props: true,
            meta: {
              anyCan: ['quotation-store', 'quotation-update', 'quotation-edit'],
              onDeniedRoute: { name: 'dashboard', replace: true }
            },
          },
          {
            path: "dados-garantia/:quotationId",
            name: "garantee-data",
            component: () =>
              import(
                /* webpackChunkName: "quotation" */ "@/views/Quotation/Guarantee/Steps/GaranteeData"
              ),
            props: true,
            meta: {
              anyCan: ['quotation-store', 'quotation-update', 'quotation-edit'],
              onDeniedRoute: { name: 'dashboard', replace: true }
            },
          },
          {
            path: "resumo-cotacao/:quotationId",
            name: "quotation-summary",
            component: () =>
              import(
                /* webpackChunkName: "quotation" */ "@/views/Quotation/Guarantee/Steps/QuotationSummary"
              ),
            props: true,
            meta: {
              anyCan: ['quotation-store', 'quotation-update', 'quotation-edit'],
              onDeniedRoute: { name: 'dashboard', replace: true }
            },
          },
          {
            path: "dados-segurado/:quotationId",
            name: "insured-data",
            component: () =>
              import(
                /* webpackChunkName: "quotation" */ "@/views/Quotation/Guarantee/Steps/InsuredData"
              ),
            props: true,
            meta: {
              anyCan: ['quotation-store', 'quotation-update', 'quotation-edit'],
              onDeniedRoute: { name: 'dashboard', replace: true }
            },
          },
          {
            path: "detalhes/:quotationId",
            name: "quotation-details",
            component: () =>
              import(
                /* webpackChunkName: "quotation" */ "@/views/Quotation/Guarantee/Steps/Details"
              ),
            props: true,
            meta: {
              anyCan: ['quotation-store', 'quotation-update', 'quotation-edit'],
              onDeniedRoute: { name: 'dashboard', replace: true }
            },
          },
          {
            path: "final/:quotationId",
            name: "final-feedback-quotation",
            component: () =>
              import(
                /* webpackChunkName: "quotation" */ "@/views/Quotation/Guarantee/Steps/FinalFeedback"
              ),
            props: true,
            meta: {              
              anyCan: ['quotation-store', 'quotation-update', 'quotation-edit'],
              onDeniedRoute: { name: 'dashboard', replace: true },
              wizardNavigator: {
                disableStepsNavigation: true
              }
            }
          }
        ]
      },
      {
        path: "seguro-fianca-locataria",
        component: () =>
          import(
            /* webpackChunkName: "quotation" */ "@/views/Quotation/RenterGuarantee/Index"
          ),
        props: true,
        children: [
          {
            path: ":renterQuotationId?",
            name: "renter-guarantee-quotation",
            component: () =>
              import(
                /* webpackChunkName: "quotation" */ "@/views/Quotation/RenterGuarantee/Steps/GeneralData"
              ),
            props: true
          },
          {
            path: "dados-do-imovel-pretendido/:renterQuotationId",
            name: "data-property-desired",
            component: () =>
              import(
                /* webpackChunkName: "quotation" */ "@/views/Quotation/RenterGuarantee/Steps/PropertyDesired"
              ),
            props: true
          },
          {
            path: "vigencia-do-contrato/:renterQuotationId",
            name: "contract-vilidity",
            component: () =>
              import(
                /* webpackChunkName: "quotation" */ "@/views/Quotation/RenterGuarantee/Steps/TermContract"
              ),
            props: true
          },
          {
            path: "final/:renterQuotationId",
            name: "final-renter-guarantee-quotation",
            component: () =>
              import(
                /* webpackChunkName: "quotation" */ "@/views/Quotation/RenterGuarantee/Steps/FinishContract"
              ),
            props: true
          }
        ]
      },
      {
        path: "seguro-risco-engenharia",
        name: "engineering-risk-quotation",
        component: () =>
          import(
            /* webpackChunkName: "quotation" */ "@/views/Quotation/EngineeringRisk/Index"
          )
      },
      {
        path: "seguro-responsabilidade-civil",
        name: "civil-responsability-quotation",
        component: () =>
          import(
            /* webpackChunkName: "quotation" */ "@/views/Quotation/CivilResponsability/Index"
          )
      }
    ]
  }
];

export default routes;
