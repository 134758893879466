<template>
  <b-row align-h="center">
    <b-button @click="logout" variant="outline-primary">Sair</b-button>
  </b-row>
</template>

<script>
import authenticationService from '@/services/authentication';

export default {
  name: 'Home',
  methods: {
    logout() {
      this.$store.commit("SET_PREVENT_REDIRECTION");
      authenticationService.logout().then(() => {
        this.$store.commit("SET_LOGOUT");
        this.$router.push({ name: "login" });
      });
    }
  }
}
</script>
