import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    apiToken: '',
    user: {},
    filtersTable: {
      policy_holder: '',
      process: '',
      insured: '',
      status: '',
      start_created: '',
      end_created: '',
      document: '',
      insurer : '',
      insurance_category : '',
      expiration_date : '',
      start_date : '',
      end_date : '',
    },
    logout: false,
    lawyer: {
      riskAnalysis: {
        //step-1
        personType: null,
        name: null,
        document: null,
        email: null,
        oab: null,
        activities: null,
        // step-2
        claims: null,
        previusKnowledge: null,
        claimsLastYear: null,
        complainant: null,
        // step-3
        insuredAmount: null,
        franchise: null,
        retroactivity: null,
        startDate: null,
        annualBilling: null,
        franchiseList: null,
        // response-3
        identifier: null,
        quotationId: null,
        installmentsBillet: null,
        installmentsCreditCard: null,
      },
      makePolicy: {
        //step-4
        birthDate: null,
        phone: null,
        gender: null,
        exposedPerson: null,
        worthEstimated: null,
        incomeMonthly: null,
        profession: null,
        showMoreFields: null,
        //step-5
        zipcode: null,
        address: null,
        complement: null,
        number: null,
        neighborhood: null,
        city: null,
        uf: null,
        // step-6
        documentPayer: null,
        installment: null,
        cardNumber: null,
        holder: null,
        brand: null,
        cardExpiration: null,
        cvv: null,
        methodPaymentBillet: null,
      }
    }
  },
  mutations: {
    SET_API_TOKEN (state, token) {
      state.apiToken = token
    },
    SET_USER (state, user) {
      var aux = state.user.permissions;
      state.user = user;
      state.user.permissions = aux;
    },
    SET_LOGOUT (state, reload = true) {
      state.apiToken = ''
      state.user = {}
      // Forçando reload para acl recompilar e funcionar corretamente.
      if (reload) {
        document.location.reload(true)
      }
    },
    SET_PREVENT_REDIRECTION (state, logout = true) {
      state.logout = logout
    },
    SET_PERMISSIONS (state, permissions) {
      state.user.permissions = permissions
    },
    SET_FILTER (state, filter) {
      state.filtersTable = filter;
    },
    SET_FIELDS_LAWYER_RISK_ANALYSIS(state, fieldsToUpdate) {
      for (const field in fieldsToUpdate) {
        if (Object.prototype.hasOwnProperty.call(state.lawyer.riskAnalysis, field)) {
          state.lawyer.riskAnalysis[field] = fieldsToUpdate[field];
        } else {
          console.error(`Campo '${field}' não encontrado no estado.`);
        }
      }
    },
    SET_FIELDS_LAWYER_MAKE_POLICY(state, fieldsToUpdate) {
      for (const field in fieldsToUpdate) {
        if (Object.prototype.hasOwnProperty.call(state.lawyer.makePolicy, field)) {
          state.lawyer.makePolicy[field] = fieldsToUpdate[field];
        } else {
          console.error(`Campo '${field}' não encontrado no estado.`);
        }
      }
    },
    RESET_LAWYER(state) {
      for (let key in state.lawyer.riskAnalysis) {
        state.lawyer.riskAnalysis[key] = null;
      }
      for (let key in state.lawyer.makePolicy) {
        state.lawyer.makePolicy[key] = null;
      }
    },
  },
  getters: {
    ApiToken: state => state.apiToken,
    user: state => state.user,
    permissions: state => state.permissions,
    getFiltersTable: state => state.filtersTable,
    getPreventRedirection: state => state.logout,
    getLawyerFields: state => state.lawyer,
  },
  modules: {},
  plugins: [createPersistedState()]
})
