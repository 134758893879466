import http from "@/services/http";
import store from '@/store';
import jwt_decode from "jwt-decode";
import roles from "@/enums/roles";

const logout = () => {
  return http.post("auth/logout").then(response => {
    localStorage.removeItem("token");
    store.commit("SET_PREVENT_REDIRECTION");
    store.commit("SET_LOGOUT");
    return response;
  });
}

const logoutNotRequest = () => {
  localStorage.removeItem("token");
  store.commit("SET_PREVENT_REDIRECTION");
  store.commit("SET_LOGOUT");
}

const setToken = (accessToken, expiresIn, emailVerified) => {
  let now = new Date();
  now.setSeconds(now.getSeconds() + expiresIn);
  let date = now.toLocaleString("en-US", {timeZone: "America/Sao_Paulo"})
  let token = {
    expires_at: date,
    access_token: `Bearer ${accessToken}`,
    email_verified: emailVerified
  };
  localStorage.setItem("token", JSON.stringify(token));
  store.commit("SET_PERMISSIONS", jwt_decode(token.access_token).permissions);
}

const login = (email, password) => {
  return http
    .post("auth/login", {
      email,
      password
    })
    .then(response => {
      if (response.data.error) {
        return response;
      }
      setToken(response.data.access_token, response.data.expires_in, response.data.email_verified);
      return response;
    });
}

const me = () => {
  return http
    .post("auth/me")
    .then(response => {
      store.commit('SET_USER', response.data)
      return response;
    });
}

const loginUsingAccessToken = (accessToken, expires_in, emailVerified) => {
  setToken(accessToken, expires_in, emailVerified);
}

const isMaster = () => {
  const user = store.getters.user;
  return user.master == true;
}

const accessPanel = () => {
  const user = store.getters.user;
  return user.has_access_panel == true;
}

const isCompany = () => {
  const user = store.getters.user;
  return user.document && (user.document).replace(/\D/g, '').length == 14;
}

const hasCompany = () => {
  const user = store.getters.user;
  return user.companies && (parseInt(user.companies.length) > 0);
}

const getCompanies = () => {
  const user = store.getters.user;
  return hasCompany() ? [].concat(user.companies || []) : [];
}

//VERIFICANDO SE O TOKEN É VALIDO
async function hasTokenValid(next) {
  let tokenJson = localStorage.getItem("token");
  let validation = true;
  if (tokenJson) {
    let token = JSON.parse(tokenJson);
    let expires_at = Date.parse(token.expires_at);
    validation = expires_at > new Date().getTime()
    if (!validation) {
      let refresh = await http
        .post("auth/refresh")
        .then(response => {
          if (response.data.error) {
            logoutNotRequest()
          }
          me()
          setToken(response.data.access_token, response.data.expires_in, response.data.email_verified);
          return true;
        }).catch(() => {
          logoutNotRequest()
        });
      if (refresh) {
        validation = true;
      }
    }
  } else {
    next({name: 'login'})
  }
  return validation;
}

//VERIFICANDO SE O EMAIL FOI CONFIRMADO
const hasEmailVerified = () => {
  let tokenJson = localStorage.getItem("token");
  let response = false
  if (tokenJson) {
    return http
      .post("auth/confirmed-email-check")
      .then(response => {
        if (response.data.error) {
          response = false
        }
        response = true
      }).catch(() => {
        logoutNotRequest()
        return false
      });
  }
  return response
}

//VERIFICANDO SE ELE TEM PERMISSAO DE MASTER PARA ACESSAR A ROTA
function tryAccessAsMaster(to, next) {
  if (!isMaster()) {
    logoutNotRequest()
    next({
      name: 'login',
    });
    return;
  }
  next();
}

const forgotPassword = (email) => {
  return http
    .post("auth/forgot-password", {
      email
    });
}

const resetPassword = (email, password, password_confirmation, token) => {
  return http
    .post("auth/reset-password", {
      email,
      password,
      password_confirmation,
      token
    });
}

const loginLanding = (token) => {
  return http
    .post("auth/landing-login", {
      token
    })
    .then(response => {
      if (response.data.error) {
        return response;
      }
      setToken(response.data.token.access_token, response.data.token.expires_in, response.data.token.email_verified);
      return response;
    });
}

function isValidToken() {
  let tokenJson = localStorage.getItem("token");
  let validation = false;
  if (tokenJson) {
    let token = JSON.parse(tokenJson);
    let expires_at = Date.parse(token.expires_at);
    validation = expires_at > new Date().getTime();
    return validation;
  }
  return validation;
}

const isCommercial = () => {
  const user = store.getters.user;
  return (user.master == true) || (user.role == roles.COMMERCIAL);
}

export default {
  logout,
  login,
  hasTokenValid,
  loginUsingAccessToken,
  hasEmailVerified,
  me,
  forgotPassword,
  resetPassword,
  loginLanding,
  isMaster,
  accessPanel,
  isCompany,
  hasCompany,
  getCompanies,
  tryAccessAsMaster,
  isValidToken,
  isCommercial
}