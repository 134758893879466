import router from '@/router';
import store from '@/store'
import Vue from 'vue';

import VueCompositionAPI from '@vue/composition-api';
Vue.use(VueCompositionAPI);
// import { computed } from '@vue/composition-api'; // import computed function
import { createAcl, defineAclRules } from 'vue-simple-acl/dist/vue-simple-acl.vue2';
import listPermissions from '@/enums/listPermissionsAcl';

// Captura usuário Logado
var user = store.getters.user;

const rules = () => defineAclRules((setRule) => {

    // Define regras
    for (let item in listPermissions) { // Todas permissions
        setRule(listPermissions[item], (user) => user.permissions.includes(listPermissions[item]));
    }
});

const simpleAcl = createAcl({
    user, 
    rules,
    router,
});

export default simpleAcl;