export default {
  DRAFT : 1,
  ANALYSIS : 2,
  APPROVED : 3,
  ISSUE : 4,
  ISSUED : 5,
  DECLINED : 6,
  ISSUE_FAILURE : 7,
  ACTIVE: 8,
  CANCELED: 9,
  ENDORSED: 10,
  REQUESTED: 11,
  PROGRESS: 12,
  PENDING: 13,
  RESOLVED: 14,
  AWAITING_DOCUMENTATION: 15,
  FINISHED: 16,
  RENEWED: 17,
  AWAITING_APPROVAL: 18
}
