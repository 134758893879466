<template>
  <div class="container container-cozy">
    <div class="card card-page">
      <div class="card-header border-bottom">
        <h2 class="text-center uppercase">Registre-se</h2>
      </div>
      <div class="card-body shadow">

        <div v-if="invite">
          <validation-observer ref="observer" v-slot="{ handleSubmit, invalid }">
            <b-form
              @submit.stop.prevent="handleSubmit(register)"
              class="form"
              autocomplete="off"
            >
              <div class="row">

                <p>
                  O usuário <strong>{{ invite.user.name }}</strong> convidou você para utilizar a plataforma FINN Seguros.
                </p>

                <div class="col-12 text-center">
                  <hr />
                  <div class="hr-label">Preencha seus dados abaixo</div>
                </div>
                
                <div class="col-12" v-if="alertMessage">
                  <div
                    class="alert-custom alert-danger-custom list-errors-custom"
                  >
                    <ul
                      class="pt-3 list-errors"
                      v-if="Array.isArray(alertMessage)"
                    >
                      <li v-for="er in alertMessage" :key="er">{{ er[0] }}</li>
                    </ul>
                    <div v-else>
                      {{ alertMessage }}
                    </div>
                  </div>
                </div>

                <div class="col-12 col-lg-6 mb-3">
                  <validation-provider
                    name="Nome"
                    rules="required|max:191|fullname"
                    v-slot="validationContext"
                  >
                    <label class="dbo-ui">Seu nome completo</label>
                    <b-form-input
                      v-model="user.name"
                      :state="getValidationState(validationContext)"
                      placeholder="Preencha seu nome completo"
                    ></b-form-input>
                    <b-form-invalid-feedback>{{
                      validationContext.errors[0]
                    }}</b-form-invalid-feedback>
                  </validation-provider>
                </div>

                <div class="col-12 col-lg-6 mb-3">
                  <validation-provider
                    name="Documento"
                    rules="required|min:14"
                    v-slot="validationContext"
                    vid="document"
                  >
                    <label class="dbo-ui">Seu CPF</label>
                    <b-form-input
                      v-model="user.document"
                      :state="getValidationState(validationContext)"
                      placeholder="Preencha o número do seu CPF"
                      v-mask="'###.###.###-##'"
                    ></b-form-input>
                    <b-form-invalid-feedback>{{
                      validationContext.errors[0]
                    }}</b-form-invalid-feedback>
                  </validation-provider>
                </div>

                <div class="col-12 col-lg-6 mb-3">
                  <validation-provider
                    name="Telefone"
                    rules="required"
                    v-slot="validationContext"
                    vid="phone"
                  >
                    <label class="dbo-ui">Telefone de contato</label>
                    <b-form-input
                      v-model="user.phone"
                      v-mask="['(##) ####-####', '(##) #####-####']"
                      :state="getValidationState(validationContext)"
                      placeholder="Preencha seu telefone principal"
                    ></b-form-input>
                    <b-form-invalid-feedback>{{
                      validationContext.errors[0]
                    }}</b-form-invalid-feedback>
                  </validation-provider>
                </div>

                <div class="col-12 col-lg-6 mb-3">
                  <validation-provider
                    name="E-mail"
                    rules="required|email"
                    v-slot="validationContext"
                    vid="email"
                  >
                    <label class="dbo-ui">E-mail</label>
                    <b-form-input
                      type="email"
                      v-model="user.email"
                      :state="getValidationState(validationContext)"
                      placeholder="Preencha seu e-mail principal"
                    ></b-form-input>
                    <b-form-invalid-feedback>{{
                      validationContext.errors[0]
                    }}</b-form-invalid-feedback>
                  </validation-provider>
                </div>

                <div class="col-12 col-lg-6 mb-3">
                  <validation-provider
                    name="Senha"
                    rules="required|min:6"
                    v-slot="validationContext"
                    vid="password"
                  >
                    <label class="dbo-ui">Sua senha</label>
                    <b-form-input
                      type="password"
                      v-model="user.password"
                      :state="getValidationState(validationContext)"
                      placeholder="Digite aqui sua senha desejada"
                    ></b-form-input>

                    <b-form-invalid-feedback>{{
                      validationContext.errors[0]
                    }}</b-form-invalid-feedback>
                  </validation-provider>
                </div>

                <div class="col-12 col-lg-6 mb-3">
                  <validation-provider
                    name="Confirmação de senha"
                    rules="required|confirmed:password"
                    v-slot="validationContext"
                    vid="confirm_password"
                  >
                    <label class="dbo-ui">Preencha sua senha novamente</label>
                    <b-form-input
                      type="password"
                      v-model="user.confirm_password"
                      :state="getValidationState(validationContext)"
                      placeholder="Confira sua senha"
                    ></b-form-input>

                    <b-form-invalid-feedback>{{
                      validationContext.errors[0]
                    }}</b-form-invalid-feedback>
                  </validation-provider>
                </div>
                
                <div class="col-12 py-3 text-center">
                  <hr />
                  <p class="text-muted mb-0"><span class="hr-label mx-auto">LISTA DE TOMADORES</span></p>
                    
                  <b-table :fields="fields" :items="items">
                    <template #cell(cnpj)="data">
                      <span class="centered-cell">{{ data.value != "" ? formatCnpj(data.value) : "---" }}</span>
                    </template>
                  </b-table>
                </div>
                

                <div class="col-12 col-lg-6 mb-3">
                  <validation-provider
                    name="Politica de privacidade"
                    rules="required"
                    v-slot="validationContext"
                    vid="policy"
                  >
                    <b-form-checkbox
                      v-model="user.policy"
                      :state="user.policy"
                      placeholder="Campo politica de privacidade é obrigatorio"
                    >
                      <p>Eu li e aceito os 
                      <a class="text-decoration-none" href="https://finnseguros.com.br/politica-de-privacidade/" target="_blank">
                      termos e condições.</a></p>
                    </b-form-checkbox>
                    <b-form-invalid-feedback>{{
                      validationContext.errors[0]
                    }}</b-form-invalid-feedback>
                  </validation-provider>
                </div>

                <div class="col-12 mt-3">
                  <button
                    type="submit"
                    :disabled="invalid"
                    class="btn btn-primary btn-pretty d-block mx-auto"
                  >
                    <b-spinner v-if="loading" small></b-spinner>
                    <i v-else class="fa fa-check"></i> Registre-se
                  </button>
                </div>

              </div>
            </b-form>
          </validation-observer>

        </div>

        <div v-else>
          <h2 class="mb-5">Convite não encontrado!</h2>
                
          <div v-if="alertMessage" class="col-12">
            <b-alert show :variant="alertVariant">{{ alertMessage }}</b-alert>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import userService from "@/services/user";
import commomsHelperMixin from '@/mixins/commomsHelper';
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mask } from "vue-the-mask";
import { extend } from "vee-validate";

extend("fullname", (value) => {
  const nameParts = value.split(" ");
  return nameParts.length > 1 || "Você deve informar seu nome completo";
});

export default {
  name: "Register",
  props: {
    userInviteId: {
      type: String
    }
  },
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    mask,
  },
  mixins: [commomsHelperMixin],
  data: () => ({
    loading: false,
    error: null,
    user: {},
    invite: null,
    items: [],
  }),
  created(){
    this.getInvite(this.userInviteId);
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    getInvite(id) {
      userService
        .getInviteById(id)
        .then((response) => {
          
          if (response.data.error) {
            this.error = response.data.error;
          }

          if (!response.data.user_created) {
            this.invite = response.data;
            this.user.email = this.invite.email;
            this.items = this.invite.user.companies;
          }
        })
        .catch(() => {
          this.showError('Não foi possível buscar o convite');
        })
    },
    register() {
      this.loading = true;
      this.resetAlert();
      
      if (this.userInviteId) 

      userService
        .registerInvited(this.userInviteId, this.user)
        .then((response) => {
          if (response.data.error) {
            this.error = response.data.error;
          }
          this.$gtm.trackEvent({
            event: "Registro_ok",
          });
          this.$router.push({ name: "quotation" });
        })
        .catch((error) => {
          this.showError(Object.values(error.response.data.errors));
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  computed: {
    fields() {
      var columns = [
        {
          key: "cnpj",
          label: "CNPJ",
          tdClass: "font-6",
          thClass: "table-pretty dbo-ui centered-header",
        },
        {
          key: "company_name",
          label: "Nome do Tomador",
          tdClass: "font-6",
          thClass: "table-pretty dbo-ui centered-header",
        },
      ];
      return columns;
    },
  },
};
</script>

<style scoped>
a {
  cursor: pointer;
}
</style>
