<template>
  <div class="container container-small">
    <div class="card card-page">
      <div class="card-header border-bottom">
        <h2 class="text-center">Alterar senha</h2>
      </div>
      <div class="card-body shadow">
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
          <b-form @submit.stop.prevent="handleSubmit(resetPassword)" class="form" autocomplete="off">
            <div class="row">
              <div v-if="alertMessage" class="col-12">
                <div class="alert-custom alert-danger-custom">{{ alertMessage }}</div>
              </div>

              <div class="col-12 mb-3">
                <validation-provider
                    name="E-mail"
                    rules="required|email"
                    v-slot="validationContext"
                  >
                    <label class="dbo-ui" for="">Seu e-mail</label>
                    <b-form-input
                      v-model="email"
                      :state="getValidationState(validationContext)"
                    ></b-form-input>

                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </validation-provider>
              </div>


              <div class="col-12 mb-3">
                <validation-provider
                  name="Senha"
                  rules="required"
                  v-slot="validationContext"
                  vid="password"
                >
                  <label class="dbo-ui">Digite uma nova senha</label>
                  <b-form-input
                    type="password"
                    v-model="password"
                    :state="getValidationState(validationContext)"
                    placeholder="Digite aqui sua senha desejada"
                  ></b-form-input>

                  <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </validation-provider>
              </div>

              <div class="col-12 mb-3">
                <validation-provider
                  name="Confirmação de senha"
                  rules="required|confirmed:password"
                  v-slot="validationContext"
                >
                  <label class="dbo-ui">Preencha sua senha novamente</label>
                  <b-form-input
                    type="password"
                    v-model="password_confirmation"
                    :state="getValidationState(validationContext)"
                    placeholder="Confira sua senha"
                  ></b-form-input>

                  <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </validation-provider>
              </div>

              <div class="col-12">
                <b-button
                    type="submit"
                    variant="primary"
                    class="btn btn-primary btn-pretty d-block mx-auto"
                  >
                  <b-spinner v-if="loading" small></b-spinner>
                  <i v-else class="fas fa-save"></i> Alterar senha
                </b-button>
              </div>
            </div>

          </b-form>
        </validation-observer>
      </div>
    </div>
  </div>
</template>

<script>
import authenticationService from "@/services/authentication";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  name: "ResetPassword",
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data: () => ({
    email: null,
    password: null,
    password_confirmation: null,
    loading: false
  }),
  created() {
    this.email = this.$route.query.email;
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    resetPassword() {
      this.loading = true;
      this.resetAlert();
      authenticationService.resetPassword(this.email, this.password, this.password_confirmation, this.$route.query.token)
      .then(response => {
        if (response.data.status == 200) {
          this.$router.push({ name: "login" });
          return;
        }
        this.showError(response.data.message);
      })
      .catch(() => {
        this.showError('Ocorreu um erro ao tentar alterar a senha.');
      })
      .finally(() => {
        this.loading = false;
      });
    }
  }
};
</script>

<style>
</style>
