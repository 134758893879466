import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import router from './router'
import './plugins/apex-charts';
import store from './store'
import './plugins/vee-validate';
import './plugins/v-money';
import './plugins/vue-dropzone';

import './styles/custom.scss'
import './styles/custom.css'
//import './styles/_bootstrap-extras.scss'
import vSelect from 'vue-regex-input';
import acl from './plugins/acl';
import alertMixin from "@/mixins/messageAlert";
import VueGtm from 'vue-gtm';

Vue.mixin(alertMixin);
Vue.component('vue-regex-input', vSelect);
Vue.config.productionTip = false

Vue.use(acl);

Vue.use(VueGtm, {
  id: process.env.VUE_APP_GTM_ID,
  defer: false,
  enabled: true,
  debug: false,
  loadScript: true,
  vueRouter: router,
  trackOnNextTick: false,
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')


