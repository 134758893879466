export default {
  USER_UPDATE : "user-update",
  USER_STORE : "user-store",
  USER_SHOW : "user-show",
  USER_LIST : "user-list",
  USER_EDIT : "user-edit",
  USER_DELETE : "user-delete",
  QUOTATION_UPDATE : "quotation-update",
  QUOTATION_STORE : "quotation-store",
  QUOTATION_SHOW : "quotation-show",
  QUOTATION_LIST : "quotation-list",
  QUOTATION_EDIT : "quotation-edit",
  QUOTATION_DELETE : "quotation-delete",
  POLICY_UPDATE : "policy-update",
  POLICY_STORE : "policy-store",
  POLICY_SHOW : "policy-show",
  POLICY_LIST : "policy-list",
  POLICY_ISSUE : "policy-issue",
  POLICY_IMPORT : "policy-import",
  POLICY_EXPORT : "policy-export",
  POLICY_EDIT : "policy-edit",
  POLICY_DELETE : "policy-delete",
  PROCESS_UPDATE : "process-update",
  PROCESS_STORE : "process-store",
  PROCESS_SHOW : "process-show",
  PROCESS_LIST : "process-list",
  PROCESS_IMPORT : "process-import",
  PROCESS_EDIT : "process-edit",
  PROCESS_DELETE : "process-delete",
  ENDORSEMENT_UPDATE : "endorsement-update",
  ENDORSEMENT_STORE : "endorsement-store",
  ENDORSEMENT_SHOW : "endorsement-show",
  ENDORSEMENT_LIST : "endorsement-list",
  ENDORSEMENT_IMPORT : "endorsement-import",
  ENDORSEMENT_EDIT : "endorsement-edit",
  ENDORSEMENT_DELETE : "endorsement-delete",
  CLAIM_UPDATE : "claim-update",
  CLAIM_STORE : "claim-store",
  CLAIM_SHOW : "claim-show",
  CLAIM_LIST : "claim-list",
  CLAIM_IMPORT : "claim-import",
  CLAIM_EDIT : "claim-edit",
  CLAIM_DELETE : "claim-delete",
  CERTIFICATE_LIST : "certificate-list",
  CERTIFICATE_IMPORT : "certificate-import",
  CERTIFICATE_SHOW : "certificate-show",
  DOCUSIGN_STORE : "docusign-store",
  INTEGRATION_LIST : "integration-list",
}
