<template>
  <div
    class="container container-small"
  >
    <div class="card card-page">
      <div class="card-header border-bottom">
        <h2 class="text-center uppercase">Solicite seu acesso</h2>
      </div>
      <div class="card-body shadow">
        <div>
          <p>Sua solicitação de acesso à plataforma foi recebida com sucesso!</p>
          <p>Em breve, entraremos em contato para dar andamento à liberação do seu login.</p>
          <p>Caso sua demada seja urgente, entre em contato pelo telefone <a :href="'tel:' + (comercialPhone).replace(/\D/g, '')" target="_blank">{{ comercialPhone }}</a> e fale com um de nossos especialistas.</p>
          <p>Equipe <strong>FINN Seguros</strong>.</p>
          <p class="text-center">
            <router-link class="btn btn-pretty btn-primary mt-3"
              :to="{ name: 'login' }"
            ><i class="fa fa-arrow-left"></i> Voltar</router-link>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import enumContactInfo from "@/enums/contactInfo";

export default {
  name: "RegisteredMessage",
  data: () => ({
    show: false,
    comercialPhone: enumContactInfo.APP_FINN_TELEPHONE,
    isLoggedIn: false
  }),
}
</script>
