const routes = [
  {
    path: '/advogado',
    name: 'lawyer',
    redirect: { name: 'step-1' },
    component: () => import(/* webpackChunkName: "lawyer" */ '@/views/Lawyer/Index'),
    children: [
      {
        path: "step-1",
        name: "step-1",
        component: () =>
          import(
            /* webpackChunkName: "lawyer" */ "@/views/Lawyer/Steps/Step1"
          ),
      },
      {
        path: "step-2",
        name: "step-2",
        component: () =>
          import(
            /* webpackChunkName: "lawyer" */ "@/views/Lawyer/Steps/Step2"
          ),
      },
      {
        path: "step-3",
        name: "step-3",
        component: () =>
          import(
            /* webpackChunkName: "lawyer" */ "@/views/Lawyer/Steps/Step3"
          ),
      },
      {
        path: "step-4",
        name: "step-4",
        component: () =>
          import(
            /* webpackChunkName: "lawyer" */ "@/views/Lawyer/Steps/Step4"
          ),
      },
      {
        path: "step-5",
        name: "step-5",
        component: () =>
          import(
            /* webpackChunkName: "lawyer" */ "@/views/Lawyer/Steps/Step5"
          ),
      },
      {
        path: "step-6",
        name: "step-6",
        component: () =>
          import(
            /* webpackChunkName: "lawyer" */ "@/views/Lawyer/Steps/Step6"
          ),
      },
      {
        path: "step-7",
        name: "step-7",
        component: () =>
          import(
            /* webpackChunkName: "lawyer" */ "@/views/Lawyer/Steps/Step7"
          ),
      },
      {
        path: "step-8",
        name: "step-8",
        component: () =>
          import(
            /* webpackChunkName: "lawyer" */ "@/views/Lawyer/Steps/Step8"
          ),
      },
    ]
  },
];

export default routes;
