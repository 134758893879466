import Login from '@/views/Auth/Login.vue'
// import Logout from '@/views/Auth/Logout.vue'
import Register from '@/views/Auth/Register/Index.vue'
import RegisteredMessage from '@/views/Auth/Register/RegisteredMessage.vue'
import ForgotPassword from '@/views/Auth/ForgotPassword.vue'
import ResetPassword from '@/views/Auth/ResetPassword.vue'
import AfterEmailConfirmation from '@/views/Auth/AfterEmailConfirmation.vue'
import EmailConfirmationPending from '@/views/Auth/EmailConfirmationPending.vue'
import LoginLandingConfirmation from '@/views/Auth/LoginLandingConfirmation.vue'
import RegisterInvited from '@/views/Auth/Register/Invited.vue'

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      allowAnonymous: true,
    },
  },
  // {
  //   path: '/logout',
  //   name: 'logout',
  //   component: Logout,
  //   meta: {
  //     allowAnonymous: true,
  //   },
  // },
  {
    path: '/registre-se',
    name: 'register',
    component: Register,
    meta: {
      allowAnonymous: true,
    },
  },
  {
    path: '/registro-sucesso',
    name: 'register-success',
    component: RegisteredMessage,
    meta: {
      allowAnonymous: true,
    },
  },
  {
    path: '/esqueci-minha-senha',
    name: 'forgot-password',
    component: ForgotPassword,
    meta: {
      allowAnonymous: true,
    },
  },
  {
    path: '/resetar-senha',
    name: 'reset-password',
    component: ResetPassword,
    meta: {
      allowAnonymous: true,
    },
  },
  {
    path: '/email-confirmado',
    name: 'after-email-confirmation',
    component: AfterEmailConfirmation,
    meta: {
      allowAnonymous: true,
    },
  },
  {
    path: '/confirmacao-email-pendente',
    name: 'email-confirmation-pending',
    component: EmailConfirmationPending,
    props: true,
    meta: {
      allowEmailConfirmationPending: true,
    },
  },
  {
    path: '/login-landing',
    name: 'login-landing',
    component: LoginLandingConfirmation,
    meta: {
      allowAnonymous: true,
    },
  },
  {
    path: '/registrar-convidado/:userInviteId',
    name: 'register-invited',
    props: true,
    component: RegisterInvited,
    meta: {
      allowAnonymous: true,
    },
  },
];

export default routes;
