<template>
  <div class="container container-small">
    <div class="card card-page">
      <div class="card-header border-bottom">
        <h2 class="text-center">Login</h2>
      </div>
      <div class="card-body shadow">
        <div v-if="alertMessage" class="col-12">
          <div class="alert-custom alert-danger-custom">{{ alertMessage }}</div>
        </div>
        <h1 class="text-center">Aguarde!</h1>
        <p>Conectando no sistema. <b-spinner class="ml-2" v-if="loading" small></b-spinner></p>
      </div>
    </div>
  </div>
</template>

<script>
import authenticationService from "@/services/authentication";

export default {
  name: "LoginLandingConfirmation",
  data: () => ({
    loading: false,
  }),
  created() {
    if (authenticationService.hasEmailVerified()) {
      this.$router.push({ name: "home" });
    }
    this.autoLogin();
  },
  methods: {
    autoLogin() {
      this.loading = true;
      this.resetAlert();
      authenticationService
        .loginLanding(this.$route.query.token)
        .then(response => {
          if (response.data.error) {
            this.showError(response.data.error);
            this.$router.push({ name: "login" });
            return;
          }
          this.$store.commit('SET_USER', response.data.user);
          this.$router.push({ name: "garantee-types-selection" });
        }).catch(() => {
          this.showError('Ocorreu um erro ao tentar logar.');
          this.$router.push({ name: "login" });
        }).finally(() => {
          this.loading = false;
        });
    }
  }
}
</script>

<style>

</style>
