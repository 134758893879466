<template>
  <div class="container container-small">
    <div class="card card-page">
      <div class="card-header border-bottom">
        <h2 class="text-center">Confirmaçao de e-mail</h2>
      </div>
      <div class="card-body shadow">

      </div>
    </div>
  </div>
</template>

<script>
import authenticationService from "@/services/authentication";

export default {
  name: "AfterEmailConfirmation",
  created() {
    if (authenticationService.hasEmailVerified()) {
      this.$router.push({ name: "home" });
    }
    if (!this.$route.query.access_token || !this.$route.query.expires_in) {
      this.$router.push({ name: "login" });
    }

    authenticationService.loginUsingAccessToken(
      this.$route.query.access_token,
      this.$route.query.expires_in,
      true
    );
    authenticationService.me().then(response => {
    if (response.data.error) {
        this.error = response.data.error;
      }
    }).catch(() => {
      this.error = "Ocorreu um erro ao tentar recuperar informações do usuário";
    });
    this.$router.push({ name: "home" });
  }
};
</script>

<style>
</style>
