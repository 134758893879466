<template>
  <div class="container container-small">
    <div class="card card-page">
      <div class="card-header border-bottom">
        <h2 class="text-center">Login</h2>
      </div>
      <div class="card-body shadow">
        <b-alert v-if="alertMessage" show :variant="alertVariant">{{ alertMessage }}</b-alert>
        <p><strong>Seu acesso ainda não foi liberado.</strong></p>
        <p>Entre em contato com <strong>{{ email }}</strong>.</p>

        <p class="d-flex justify-content-between">
          <router-link class="btn btn-secondary mt-3"
            :to="{ name: 'login' }"
          ><i class="fa fa-arrow-left"></i> Voltar</router-link>
          <b-link @click="resendEmailConfirmationLink" class="btn btn-primary mt-3">
            Reenviar e-mail de confirmação
            <b-spinner v-if="loading" small></b-spinner>
            <i v-else class="fa fa-arrow-right"></i>
          </b-link>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import authenticationService from "@/services/authentication";
import http from '@/services/http';

export default {
  name: "EmailConfirmationPending",
  props: {
    email: {
      type: String,
      default: () => ''
    }
  },
  computed: {
    emailUser () {
      return this.email;
    }
  },
  data: () => ({
    loading: false
  }),
  created() {
    if (authenticationService.hasEmailVerified()) {
      this.$router.push({ name: "home" });
    }
  },
  methods: {
    resendEmailConfirmationLink() {
      this.loading = true;
      this.resetAlert();
      http
        .post("auth/resend", { 'email': this.emailUser })
        .then(response => {
          if (response.data.error) {
            this.showError(response.data.error);
            return;
          }

          this.showSuccess('E-mail reenviado com sucesso. Confira seu e-mail.');
        }).catch(() => {
          this.showError('Ocorreu um erro ao tentar reenviar o e-mail de confirmação.');
        }).finally(() => {
          this.loading = false;
        });
    }
  }
}
</script>

<style>

</style>
