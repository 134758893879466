<template>
  <div class="container container-small">
    <div class="card card-page">
      <div class="card-header border-bottom">
        <h2 class="text-center">Recuperar senha</h2>
      </div>
      <div class="card-body shadow">
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
          <b-form @submit.stop.prevent="handleSubmit(forgotPassword)" class="form" autocomplete="off">
            <div class="row">
              <div v-if="alertMessage" class="col-12">
                <div :class="alertVariant == 'success' ? 'alert-custom alert-success-custom' : 'alert-custom alert-danger-custom'">{{ alertMessage }}</div>
              </div>
              <div class="col-12">
                <p class="text-center text-muted">
                  Informe-nos seu e-mail de cadastro para receber um link de
                  recuperação de senha
                </p>
              </div>
              <div class="col-12 mb-3">
                <validation-provider
                    name="E-mail"
                    rules="required|email"
                    v-slot="validationContext"
                  >
                    <label class="dbo-ui" for="">Seu e-mail</label>
                    <b-form-input
                      v-model="email"
                      :state="getValidationState(validationContext)"
                    ></b-form-input>

                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </validation-provider>
              </div>
              <div class="col-12">
                <b-button
                    type="submit"
                    variant="primary"
                    class="btn btn-primary btn-pretty d-block mx-auto"
                  >
                  <b-spinner v-if="loading" small></b-spinner>
                  <i v-else class="fa fa-envelope"></i> Recuperar senha
                </b-button>
              </div>
            </div>

          </b-form>
        </validation-observer>
      </div>
    </div>
  </div>
</template>

<script>
import authenticationService from "@/services/authentication";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  name: "ForgotPassword",
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data: () => ({
    email: null,
    loading: false
  }),
  created() {
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    forgotPassword() {
      this.loading = true;
      this.resetAlert();
      authenticationService.forgotPassword(this.email).then(response => {
        this.showSuccess(response.data.message);
      }).catch(() => {
        this.showError('Não foi possível enviar e-mail de recuperação.');
      }).finally(() => {
        this.loading = false;
      });
    }
  }
};
</script>

<style>
</style>
